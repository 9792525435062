import { AxiosRequest } from "../axiosRequest";

const URL = "prof-certification";

export function getCertifications() {
  return AxiosRequest.get(URL);
}

export function postCertifications(data) {
  return AxiosRequest.post(URL, data);
}

export function putCertifications(data) {
  return AxiosRequest.put(URL, data);
}

export function deleteCertifications(data) {
  return AxiosRequest.delete(URL, { data });
}
