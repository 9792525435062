import React from "react";
import { Box } from "@mui/material";
import AppRoutes from "./root/AppRoutes";
import { useStyles } from "../style";

function AppRoot() {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <Box className={classes.box}>
        <AppRoutes />
      </Box>
    </Box>
  );
}

export default AppRoot;
