import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { lazy, useEffect } from "react";
import { routes } from "./routes";
import PrivateRoute from "./PrivateRoute";
import { retry } from "../../utils/commonFunctions";
import { ACCOUNT_TYPES, AUTH_STAGES } from "../../redux/constants";
import {
  clearRedirect,
  setAccountType,
  redirectToUrl,
  refreshUserDetails,
  logout,
  fetchUserDetails,
  getLookup,
} from "../../redux/actions";

const ForgotPassword = lazy(() => retry(() => import("../../components/auth/ForgotPassword")));
const SignInSelection = lazy(() => retry(() => import("../../components/auth/SignInSelection")));
const SignInContainer = lazy(() => retry(() => import("../../components/auth/SignInContainer")));
const ProfessionalSignup = lazy(() => retry(() => import("../../components/auth/ProfessionalSignup")));
const HospitalSignup = lazy(() => retry(() => import("../../components/auth/HospitalSignup")));
const OtpVerification = lazy(() => retry(() => import("../../components/auth/OtpVerification")));
const Congratulations = lazy(() => retry(() => import("../../components/auth/Congratulations")));
const ThankYou = lazy(() => retry(() => import("../../components/auth/ThankYou")));

const Shifts = lazy(() => retry(() => import("../../components/home/shifts")));

const AssignmentInfo = lazy(() => retry(() => import("../../components/home/shifts/AssignmentHistoryList")));
const AssignmentDetails = lazy(() => retry(() => import("../../components/home/shifts/AssignmentDetails")));
const Home = lazy(() => retry(() => import("../../components/home/Home")));

const Profile = lazy(() => retry(() => import("../../components/user-profile/Profile")));

const Inbox = lazy(() => retry(() => import("../../components/home/notifications")));

const FacilitiesHome = lazy(() => retry(() => import("../../components/Facilities/Home")));
const FacilitiesShifts = lazy(() => retry(() => import("../../components/Facilities/Shifts")));
const ComingSoon = lazy(() => retry(() => import("../../components/common/ComingSoon")));

function Logout() {
  const dispatch = useDispatch();
  dispatch(logout());
  return (
    <Redirect
      to={{
        pathname: routes.SIGN_IN,
      }}
    />
  );
}

function AppRoutes() {
  const authenticationReducer = useSelector((state) => state.authenticationReducer);
  const commonReducer = useSelector((state) => state.commonReducer);
  const { redirectTo, isLookupLoaded } = commonReducer;
  const { authenticated, isUserDetailsLoaded } = authenticationReducer;
  const dispatch = useDispatch();
  const history = useHistory();
  const currentPath = window.location.pathname;

  useEffect(() => {
    if (!authenticated && ["/", "/logout", ""].includes(currentPath)) {
      dispatch(redirectToUrl(currentPath));
    }
  }, []);

  useEffect(() => {
    dispatch(refreshUserDetails());
    if (authenticated && !isUserDetailsLoaded) {
      dispatch(fetchUserDetails());
    }
    if (!isLookupLoaded) {
      dispatch(getLookup());
    }
  }, []);

  useEffect(() => {
    if (redirectTo) {
      history.push(redirectTo);
      dispatch(clearRedirect());
    }
  }, [redirectTo]);

  // useEffect(() => {
  //   const { pathname } = history.location;
  //   const params = pathname.split("/");

  //   if ([routes.SIGN_IN, routes.SIGN_UP].includes(`/${params[1]}`)) {
  //     const accountType = params[2];
  //     if ([ACCOUNT_TYPES.professional, ACCOUNT_TYPES.hospital].includes(accountType)) {
  //       dispatch(setAccountType(accountType));
  //     } else {
  //       dispatch(redirectToUrl(routes.SIGN_IN));
  //     }
  //   }
  // }, []);

  return (
    <Switch>
      <Route
        exact
        path={routes.ROOT}
        render={() => <Redirect to={{ pathname: authenticated ? routes.HOME : routes.SIGN_IN }} />}
      />

      <Route exact path={routes.SIGN_IN} render={() => <SignInSelection />} />
      <Route exact path={routes.SIGN_IN + AUTH_STAGES.forgot_password} component={ForgotPassword} />
      <Route exact path={routes.SIGN_IN + AUTH_STAGES.professional} component={SignInContainer} />
      <Route exact path={routes.SIGN_IN + AUTH_STAGES.hospital} component={SignInContainer} />

      <Route exact path={routes.SIGN_UP} />

      <Route exact path={routes.SIGN_UP + AUTH_STAGES.professional} component={ProfessionalSignup} />
      <Route exact path={routes.SIGN_UP + AUTH_STAGES.hospital} component={HospitalSignup} />
      <Route exact path={routes.SIGN_UP + AUTH_STAGES.otp} component={OtpVerification} />
      <Route exact path={routes.SIGN_UP + AUTH_STAGES.congratulation} component={Congratulations} />
      <Route exact path={routes.SIGN_UP + AUTH_STAGES.thank_you} component={ThankYou} />

      <Route exact path={routes.LOGOUT} component={Logout} />

      <PrivateRoute exact path={routes.HOME} component={Home} authenticated={authenticated} />
      <PrivateRoute exact path={routes.PROFILE} component={Profile} authenticated={authenticated} />
      <PrivateRoute exact path={routes.MY_SHIFTS} component={Shifts} authenticated={authenticated} />
      <PrivateRoute exact path={routes.ASSIGNMENT_INFO} component={AssignmentInfo} authenticated={authenticated} />
      <PrivateRoute
        exact
        path={`${routes.MY_SHIFTS}/:id`}
        component={AssignmentDetails}
        authenticated={authenticated}
      />

      <PrivateRoute exact path={routes.FACILITIES_HOME} component={FacilitiesHome} authenticated={authenticated} />
      <PrivateRoute exact path={routes.FACILITIES_SHIFTS} component={FacilitiesShifts} authenticated={authenticated} />
      <PrivateRoute exact path={routes.PAYMENTS} component={ComingSoon} authenticated={authenticated} />
      <PrivateRoute exact path={routes.INBOX} component={Inbox} authenticated={authenticated} />
    </Switch>
  );
}

export default AppRoutes;
