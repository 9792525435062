import { AxiosRequest } from "./axiosRequest";

export function getUserDetails() {
  return AxiosRequest.get("shift-user");
}

export function postUserDetails(data) {
  return AxiosRequest.post("shift-user", data);
}

export function putUserDetails(data) {
  return AxiosRequest.put("shift-user", data);
}

export function getShiftDefinitions(query = "") {
  if (query.length) {
    return AxiosRequest.get(`shift-defn?${query}`);
  }
  return AxiosRequest.get("shift-defn");
}

export function getDropDownValues() {
  return AxiosRequest.get("dropdown");
}

export function getUserType(email) {
  return AxiosRequest.get(`user-type/${email}`);
}

export function postContactUs(data) {
  return AxiosRequest.post("contact-us", data);
}
