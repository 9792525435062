import { createAsyncThunk } from "@reduxjs/toolkit";
import { getProfile, postProfile, putProfile } from "../../api/profile/profile";
import {
  deleteCertifications,
  getCertifications,
  postCertifications,
  putCertifications,
} from "../../api/profile/certification";
import { deleteReferences, getReferences, postReferences, putReferences } from "../../api/profile/reference";
import { deleteLicences, getLicences, postLicences, putLicences } from "../../api/profile/licence";
import { deleteExperience, getExperience, postExperience, putExperience } from "../../api/profile/experience";
import {
  deleteEmrExperience,
  getEmrExperience,
  postEmrExperience,
  putEmrExperience,
} from "../../api/profile/emr-experience";
import { deleteEducation, getEducation, postEducation, putEducation } from "../../api/profile/education";
import { getPreferences, postPreferences, putPreferences } from "../../api/profile/preferences";

const putProfileKeys = [
  "profile_id",
  "phone_nbr",
  "alt_email",
  "date_of_birth",
  "years_of_exp",
  "address1",
  "address2",
  "city",
  "state",
  "country",
  "zipcode",
  "channel",
  "bg_legal",
  "bg_denied",
  "bg_investigation",
  "bg_defendant",
  "bg_terminated",
  "bg_performance",
  "specialties",
  "resume",
  "specialities",
];

/** ******** Profile ********* */

export const getProfileAction = createAsyncThunk(
  "getProfileAction",
  async (_, { rejectWithValue }) => {
    try {
      const response = await getProfile();
      return response;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  },
  {
    condition(_, { getState }) {
      return !getState()?.profileReducer?.isProfileLoaded && !getState()?.profileReducer?.isProfileLoading;
    },
  }
);

export const postProfileAction = createAsyncThunk("postProfileAction", async (data, { rejectWithValue }) => {
  try {
    let response = await postProfile(data);
    response = await getProfile();
    return response;
  } catch (err) {
    return rejectWithValue(err.message);
  }
});

export const putProfileAction = createAsyncThunk("putProfileAction", async (data, { rejectWithValue }) => {
  try {
    // remove keys that are not part of the profile
    const filteredData = Object.keys(data)
      .filter((key) => putProfileKeys.includes(key))
      .reduce(
        (obj, key) => {
          obj[key] = data[key];
          return obj;
        },
        { channel: "Web" }
      );

    await putProfile(filteredData);
    const response = await getProfile();

    return response;
  } catch (err) {
    return rejectWithValue(err.message);
  }
});
/** ******** End Profile ********* */

/** ******** Certifications ********* */
export const getCertificationsAction = createAsyncThunk(
  "getCertificationsAction",
  async (_, { rejectWithValue }) => {
    try {
      const response = await getCertifications();
      return response?.data || [];
    } catch (err) {
      return rejectWithValue(err.message);
    }
  },
  {
    condition(_, { getState }) {
      return (
        !getState()?.profileReducer?.isCertificationsLoaded && !getState()?.profileReducer?.isCertificationsLoading
      );
    },
  }
);

export const postCertificationsAction = createAsyncThunk(
  "postCertificationsAction",
  async (data, { rejectWithValue }) => {
    try {
      await postCertifications(data);
      const response = await getCertifications();
      return response?.data || [];
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const putCertificationsAction = createAsyncThunk(
  "putCertificationsAction",
  async (data, { rejectWithValue }) => {
    try {
      let response = await putCertifications(data);
      console.log("putCertifications", { response });
      response = await getCertifications();
      return response?.data || [];
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const deleteCertificationsAction = createAsyncThunk(
  "deleteCertificationsAction",

  async (data, { rejectWithValue }) => {
    try {
      let response = await deleteCertifications(data);
      console.log("deleteCertifications", { response });
      response = await getCertifications();
      return response?.data || [];
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);
/** ******** End Certifications ********* */

/** ******** Education ********* */
export const getEducationAction = createAsyncThunk(
  "getEducationAction",
  async (_, { rejectWithValue }) => {
    try {
      const response = await getEducation();
      console.log("getEducationAction", { response });
      return response?.data || [];
    } catch (err) {
      return rejectWithValue(err.message);
    }
  },
  {
    condition(_, { getState }) {
      return !getState()?.profileReducer?.isEducationLoaded && !getState()?.profileReducer?.isEducationLoading;
    },
  }
);

export const postEducationAction = createAsyncThunk("postEducationAction", async (data, { rejectWithValue }) => {
  try {
    let response = await postEducation(data);
    console.log("postEducation", { response });
    response = await getEducation();
    return response?.data || [];
  } catch (err) {
    return rejectWithValue(err.message);
  }
});

export const putEducationAction = createAsyncThunk("putEducationAction", async (data, { rejectWithValue }) => {
  try {
    let response = await putEducation(data);
    console.log("putEducation", { response });
    response = await getEducation();
    return response?.data || [];
  } catch (err) {
    return rejectWithValue(err.message);
  }
});

export const deleteEducationAction = createAsyncThunk(
  "deleteEducationAction",

  async (data, { rejectWithValue }) => {
    try {
      let response = await deleteEducation(data);
      console.log("deleteEducation", { response });
      response = await getEducation();
      return response?.data || [];
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);
/** ******** End Education ********* */

/** ******** Emr Experience ********* */
export const getEmrExperienceAction = createAsyncThunk(
  "getEmrExperienceAction",
  async (_, { rejectWithValue }) => {
    try {
      const response = await getEmrExperience();
      console.log("getEmrExperience", { response });
      return response?.data || [];
    } catch (err) {
      return rejectWithValue(err.message);
    }
  },
  {
    condition(_, { getState }) {
      return !getState().profileReducer.isEmrExperienceLoaded && !getState().profileReducer.isEmrExperienceLoading;
    },
  }
);

export const putEmrExperienceAction = createAsyncThunk(
  "putEmrExperienceAction",

  async (data, { rejectWithValue }) => {
    try {
      let response = await putEmrExperience(data);
      console.log("putEmrExperience", { response });
      response = await getEmrExperience();
      return response?.data || [];
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const postEmrExperienceAction = createAsyncThunk(
  "postEmrExperienceAction",
  async (data, { rejectWithValue }) => {
    try {
      let response = await postEmrExperience(data);
      console.log("postEmrExperience", { response });
      response = await getEmrExperience();
      return response?.data || [];
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const deleteEmrExperienceAction = createAsyncThunk(
  "deleteEmrExperienceAction",

  async (data, { rejectWithValue }) => {
    try {
      let response = await deleteEmrExperience(data);
      console.log("deleteEmrExperience", { response });
      response = await getEmrExperience();
      return response?.data || [];
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);
/** ******** End Emr Experience ********* */

/** ******** Experience ********* */
export const getExperienceAction = createAsyncThunk(
  "getExperienceAction",
  async (_, { rejectWithValue }) => {
    try {
      const response = await getExperience();
      console.log("getExperience", { response });
      return response?.data || [];
    } catch (err) {
      return rejectWithValue(err.message);
    }
  },
  {
    condition(_, { getState }) {
      return !getState().profileReducer.isExperienceLoaded && !getState().profileReducer.isExperienceLoading;
    },
  }
);

export const postExperienceAction = createAsyncThunk("postExperienceAction", async (data, { rejectWithValue }) => {
  try {
    let response = await postExperience(data);
    console.log("postExperience", { response });
    response = await getExperience();
    return response?.data || [];
  } catch (err) {
    return rejectWithValue(err.message);
  }
});

export const putExperienceAction = createAsyncThunk("putExperienceAction", async (data, { rejectWithValue }) => {
  try {
    let response = await putExperience(data);
    console.log("putExperience", { response });
    response = await getExperience();
    return response?.data || [];
  } catch (err) {
    return rejectWithValue(err.message);
  }
});

export const deleteExperienceAction = createAsyncThunk("deleteExperienceAction", async (data, { rejectWithValue }) => {
  try {
    let response = await deleteExperience(data);
    console.log("deleteExperience", { response });
    response = await getExperience();
    return response?.data || [];
  } catch (err) {
    return rejectWithValue(err.message);
  }
});

/** ******** End Experience ********* */

/** ******** Licences ********* */
export const getLicenceAction = createAsyncThunk(
  "getLicenceAction",
  async (_, { rejectWithValue }) => {
    try {
      const response = await getLicences();
      console.log("getLicences", { response });
      return response?.data || [];
    } catch (err) {
      return rejectWithValue(err.message);
    }
  },
  {
    condition(_, { getState }) {
      return !getState()?.profileReducer?.isLicenceLoaded && !getState()?.profileReducer?.isLicenceLoading;
    },
  }
);

export const postLicenceAction = createAsyncThunk("postLicenceAction", async (data, { rejectWithValue }) => {
  try {
    let response = await postLicences(data);
    console.log("postLicences", { response });
    response = await getLicences();
    return response?.data || [];
  } catch (err) {
    return rejectWithValue(err.message);
  }
});

export const putLicenceAction = createAsyncThunk("putLicenceAction", async (data, { rejectWithValue }) => {
  try {
    let response = await putLicences(data);
    console.log("putLicences", { response });
    response = await getLicences();
    return response?.data || [];
  } catch (err) {
    return rejectWithValue(err.message);
  }
});

export const deleteLicenceAction = createAsyncThunk("deleteLicenceAction", async (data, { rejectWithValue }) => {
  try {
    let response = await deleteLicences(data);
    console.log("deleteLicences", { response });
    response = await getLicences();
    return response?.data || [];
  } catch (err) {
    return rejectWithValue(err.message);
  }
});

/** ******** End Licences ********* */

/** ******** Reference ********* */
export const getReferenceAction = createAsyncThunk(
  "getReferenceAction",
  async (_, { rejectWithValue }) => {
    try {
      const response = await getReferences();
      console.log("getReferences", { response });
      return response?.data || [];
    } catch (err) {
      return rejectWithValue(err.message);
    }
  },
  {
    condition(_, { getState }) {
      return !getState().profileReducer.isReferenceLoaded && !getState().profileReducer.isReferenceLoading;
    },
  }
);

export const postReferenceAction = createAsyncThunk("postReferenceAction", async (data, { rejectWithValue }) => {
  try {
    await postReferences(data);
    const response = await getReferences();
    return response?.data || [];
  } catch (err) {
    return rejectWithValue(err.message);
  }
});

export const putReferenceAction = createAsyncThunk("putReferenceAction", async (data, { rejectWithValue }) => {
  try {
    let response = await putReferences(data);
    console.log("putReferences", { response });
    response = await getReferences();
    return response?.data || [];
  } catch (err) {
    return rejectWithValue(err.message);
  }
});

export const deleteReferenceAction = createAsyncThunk("deleteReferenceAction", async (data, { rejectWithValue }) => {
  try {
    await deleteReferences(data);
    const response = await getReferences();
    return response?.data || [];
  } catch (err) {
    return rejectWithValue(err.message);
  }
});
/** ******** End Reference ********* */

/** ******** Preference ********* */
export const getPreferenceAction = createAsyncThunk(
  "getPreferenceAction",
  async (_, { rejectWithValue }) => {
    try {
      const response = await getPreferences();
      return response || {};
    } catch (err) {
      return rejectWithValue(err.message);
    }
  },
  {
    condition(_, { getState }) {
      return !getState()?.profileReducer?.isPreferenceLoaded && !getState()?.profileReducer?.isPreferenceLoading;
    },
  }
);

export const postPreferenceAction = createAsyncThunk("postPreferenceAction", async (data, { rejectWithValue }) => {
  try {
    await postPreferences(data);
    const response = await getPreferences();
    return response || {};
  } catch (err) {
    return rejectWithValue(err.message);
  }
});

export const putPreferenceAction = createAsyncThunk("putPreferenceAction", async (data, { rejectWithValue }) => {
  try {
    const formData = {};
    Object.keys(data).forEach((key) => {
      formData[key] = Array.isArray(data[key]) ? data[key].map((item) => item) : data[key];
    });
    formData.channel = "Web";
    await putPreferences(formData);
    const response = await getPreferences();
    return response || {};
  } catch (err) {
    return rejectWithValue(err.message);
  }
});
/** ******** End Preference ********* */
