import { AxiosRequest } from "../axiosRequest";

const URL = "prof-education";

export function getEducation() {
  return AxiosRequest.get(URL);
}

export function postEducation(data) {
  return AxiosRequest.post(URL, data);
}

export function putEducation(data) {
  return AxiosRequest.put(URL, data);
}

export function deleteEducation(data) {
  return AxiosRequest.delete(URL, { data });
}
