import { AxiosRequest } from "../axiosRequest";

const URL = "prof-license";

export function getLicences() {
  return AxiosRequest.get(URL);
}

export function postLicences(data) {
  return AxiosRequest.post(URL, data);
}

export function putLicences(data) {
  return AxiosRequest.put(URL, data);
}

export function deleteLicences(data) {
  return AxiosRequest.delete(URL, { data });
}
