import { AxiosRequest } from "./axiosRequest";

export function getShifts() {
  return AxiosRequest.get("myshifts");
}

export function getShift(id) {
  return AxiosRequest.get(`myshift-details/${id}`);
}

export function applyShift(data) {
  return AxiosRequest.post(`apply`, data);
}

export function getCalendarShifts({ year, month }) {
  return AxiosRequest.get("calendar", { params: { year, month } });
}

export function getCalendarSwapShifts({ year, month }) {
  return AxiosRequest.get("swap-calendar", { params: { year, month } });
}

export function getSwapShiftsByType(type) {
  return AxiosRequest.get(`swap-requests?request_type=${type}`);
}

export function getSwapUsers({ shift_id, year, month, day }) {
  return AxiosRequest.get(`request-swap?shift_id=${shift_id}&year=${year}&month=${month}&day=${day}`);
}

export function postSwapShifts({ shift_user_id, swap_request_user_ids, shift_bydate_id, swap_reason }) {
  return AxiosRequest.post("request-swap", { shift_user_id, swap_request_user_ids, shift_bydate_id, swap_reason });
}

export function approveSwapShift({ swap_request_id }) {
  return AxiosRequest.put(`swap-requests?shift_swap_id=${swap_request_id}`, {});
}
