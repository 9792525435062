import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    height: "100vh",
    // overflowY: "scroll",
  },
  box: {
    width: "100%",
    height: "100%",
  },
}));
