import { ACCESS_TOKEN, ID_TOKEN } from "../constants/common";

class WindowStore {
  constructor() {
    this.getItem = this.getItem.bind(this);
    this.setItem = this.setItem.bind(this);
    this.removeItem = this.removeItem.bind(this);
    this.removeItems = this.removeItems.bind(this);
    this.clear = this.clear.bind(this);
    this.getToken = this.getToken.bind(this);
    this.setToken = this.setToken.bind(this);
    this._storage = "sessionStorage";
    // Data that's added/removed within the app (i.e. not from third party libs)
    this._webItems = new Set([ACCESS_TOKEN, ID_TOKEN]);
  }

  getItem(key) {
    const value = window[this._storage].getItem(key);
    try {
      return JSON.parse(value);
    } catch {
      return value;
    }
  }

  setItem(key, value) {
    let v = value;
    if (typeof value !== "string") {
      v = JSON.stringify(value);
    }
    window[this._storage].setItem(key, v);
  }

  removeItem(key) {
    if (this._webItems.has(key)) {
      window[this._storage].removeItem(key);
    }
  }

  removeItems(keys, storageEngine) {
    keys.forEach((key) => {
      if (this._webItems.has(key)) {
        window[storageEngine].removeItem(key);
      }
    });
  }

  clear() {
    const sessionStorageItems = Object.keys(window.sessionStorage);
    const localStorageItems = Object.keys(window.localStorage);
    this.removeItems(sessionStorageItems, "sessionStorage");
    this.removeItems(localStorageItems, "localStorage");
  }

  setToken(tokenType, value) {
    this.setItem(tokenType, value);
  }

  getToken(tokenType) {
    return window.sessionStorage.getItem(tokenType);
  }

  removeToken(tokenType) {
    window.sessionStorage.removeItem(tokenType);
  }
}

export const windowStore = new WindowStore();
