import { AxiosRequest } from "../axiosRequest";

const URL = "prof-emr";

export function getEmrExperience() {
  return AxiosRequest.get(URL);
}

export function postEmrExperience(data) {
  return AxiosRequest.post(URL, data);
}

export function putEmrExperience(data) {
  return AxiosRequest.put(URL, data);
}

export function deleteEmrExperience(data) {
  return AxiosRequest.delete(URL, { data });
}
