import { createSlice } from "@reduxjs/toolkit";
import {
  getCalendarShiftsAction,
  getCalendarSwapShiftsAction,
  getShiftAction,
  getShiftDefinitionsAction,
  getShiftsAction,
  getSwapShiftsByTypeAction,
  updateShiftsDefinitionFilterAction,
} from "../actions/shiftsActions";

const initialState = {
  shifts: {},
  isShiftsLoaded: false,
  isShiftsLoading: false,
  shift: {},
  isShiftLoaded: false,
  isShiftLoading: false,
  calendarShift: {},
  isCalendarShiftLoaded: false,
  isCalendarShiftLoading: false,
  calendarSwapShift: {},
  isCalendarSwapShiftLoaded: false,
  isCalendarSwapShiftLoading: false,
  swapShiftsInbound: {},
  swapShiftsOutbound: {},
  isSwapShiftsInboundLoaded: false,
  isSwapShiftsInboundLoading: false,
  isSwapShiftsOutboundLoaded: false,
  isSwapShiftsOutboundLoading: false,
  shiftDefnFilter: "",
  shiftDefns: [],
  isShiftDefnsLoaded: false,
};

const shiftsReducer = createSlice({
  name: "shiftsReducer",
  initialState,
  reducers: {},
  extraReducers: {
    [getShiftsAction.fulfilled]: (state, action) => {
      state.isShiftsLoaded = true;
      state.isShiftsLoading = false;
      state.shifts = action.payload;
    },
    [getShiftsAction.pending]: (state) => {
      state.isShiftsLoading = true;
      state.isShiftsLoaded = false;
    },
    [getShiftsAction.rejected]: (state) => {
      state.isShiftsLoading = false;
      state.isShiftsLoaded = true;
    },

    [getShiftAction.fulfilled]: (state, action) => {
      state.isShiftLoaded = true;
      state.isShiftLoading = false;
      state.shift = action.payload;
    },
    [getShiftAction.pending]: (state) => {
      state.isShiftLoading = true;
      state.isShiftLoaded = false;
    },
    [getShiftAction.rejected]: (state) => {
      state.isShiftLoading = false;
      state.isShiftLoaded = true;
    },

    [getCalendarShiftsAction.fulfilled]: (state, action) => {
      state.isCalendarShiftLoaded = true;
      state.isCalendarShiftLoading = false;
      state.calendarShift = action.payload;
    },
    [getCalendarShiftsAction.pending]: (state) => {
      state.isCalendarShiftLoading = true;
    },
    [getCalendarShiftsAction.rejected]: (state) => {
      state.isCalendarShiftLoading = false;
    },

    [getCalendarSwapShiftsAction.fulfilled]: (state, action) => {
      state.isCalendarSwapShiftLoaded = true;
      state.isCalendarSwapShiftLoading = false;
      state.calendarSwapShift = action.payload;
    },
    [getCalendarSwapShiftsAction.pending]: (state) => {
      state.isCalendarSwapShiftLoading = true;
    },
    [getCalendarSwapShiftsAction.rejected]: (state) => {
      state.isCalendarSwapShiftLoading = false;
    },

    [getSwapShiftsByTypeAction.fulfilled]: (state, action) => {
      if (action.payload.type === "INBOUND") {
        state.swapShiftsInbound = action.payload.response;
        state.isSwapShiftsInboundLoaded = true;
        state.isSwapShiftsInboundLoading = false;
      } else {
        state.swapShiftsOutbound = action.payload.response;
        state.isSwapShiftsOutboundLoaded = true;
        state.isSwapShiftsOutboundLoading = false;
      }
    },

    [getSwapShiftsByTypeAction.pending]: (state, action) => {
      if (action.meta.arg === "INBOUND") {
        state.isSwapShiftsInboundLoading = true;
      } else {
        state.isSwapShiftsOutboundLoading = true;
      }
    },

    [getSwapShiftsByTypeAction.rejected]: (state, action) => {
      if (action.meta.arg === "INBOUND") {
        state.isSwapShiftsInboundLoading = false;
      } else {
        state.isSwapShiftsOutboundLoading = false;
      }
    },
    [getShiftDefinitionsAction.fulfilled]: (state, action) => {
      state.shiftDefns = action.payload;
      state.isShiftDefnsLoaded = true;
    },
    [getShiftDefinitionsAction.pending]: (state) => {
      state.shiftDefns = [];
      state.isShiftDefnsLoaded = true;
    },
    [getShiftDefinitionsAction.rejected]: (state) => {
      state.shiftDefns = [];
      state.isShiftDefnsLoaded = true;
    },
    [updateShiftsDefinitionFilterAction]: (state, action) => {
      state.shiftDefnFilter = action.payload;
    },
  },
});

export default shiftsReducer;
