import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { getDropDownValues } from "../../api/misc";

export const redirectToUrl = createAction("redirectToUrl", (url) => ({
  payload: { redirectTo: url },
}));

export const clearRedirect = createAction("clearRedirect");

export const setAccountType = createAction("setAccountType", (accountType) => ({
  payload: { accountType },
}));

export const getLookup = createAsyncThunk("getLookup", async (_, { rejectWithValue }) => {
  try {
    return await getDropDownValues();
  } catch (err) {
    return rejectWithValue(err.message);
  }
});
