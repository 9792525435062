import { createSlice } from "@reduxjs/toolkit";
import {
  getLicenceAction,
  getProfileAction,
  getReferenceAction,
  getExperienceAction,
  getEmrExperienceAction,
  getEducationAction,
  getCertificationsAction,
  putProfileAction,
  putCertificationsAction,
  postCertificationsAction,
  postEducationAction,
  putEducationAction,
  postEmrExperienceAction,
  putEmrExperienceAction,
  putExperienceAction,
  postExperienceAction,
  postLicenceAction,
  putLicenceAction,
  postReferenceAction,
  putReferenceAction,
  getPreferenceAction,
  putPreferenceAction,
  postPreferenceAction,
  deleteExperienceAction,
  deleteLicenceAction,
  deleteEducationAction,
  deleteCertificationsAction,
  deleteReferenceAction,
} from "../actions";
import { postProfileAction } from "../actions/profileActions";

const initialState = {
  isProfileLoaded: false,
  isProfileLoading: false,
  profile: {},

  isCertificationsLoaded: false,
  isCertificationsLoading: false,
  certifications: [],

  isEducationLoaded: false,
  isEducationLoading: false,
  education: [],

  isEmrExperienceLoaded: false,
  isEmrExperienceLoading: false,
  emrExperience: [],

  isExperienceLoaded: false,
  isExperienceLoading: false,
  experience: [],

  isLicenceLoaded: false,
  isLicenceLoading: false,
  licence: [],

  isReferenceLoaded: false,
  isReferenceLoading: false,
  reference: [],

  isPreferenceLoaded: false,
  isPreferenceLoading: false,
  preference: {},
};

const profileReducer = createSlice({
  name: "profileReducer",
  initialState,
  reducers: {},
  extraReducers: {
    [getProfileAction.fulfilled]: (state, action) => {
      state.isProfileLoading = false;
      state.isProfileLoaded = true;
      state.profile = action.payload;
    },
    [getProfileAction.pending]: (state) => {
      state.isProfileLoading = true;
    },
    [getProfileAction.rejected]: (state) => {
      state.isProfileLoading = false;
      state.isProfileLoaded = true;
      state.profile = {};
    },

    [putProfileAction.fulfilled]: (state, action) => {
      state.isProfileLoading = false;
      state.isProfileLoaded = true;
      state.profile = action.payload;
    },
    [putProfileAction.pending]: (state) => {
      state.isProfileLoading = true;
    },
    [putProfileAction.rejected]: (state) => {
      state.isProfileLoading = false;
      state.isProfileLoaded = true;
      state.profile = {};
    },

    [postProfileAction.fulfilled]: (state, action) => {
      state.isProfileLoading = false;
      state.isProfileLoaded = true;
      state.profile = action.payload;
    },
    [postProfileAction.pending]: (state) => {
      state.isProfileLoading = true;
    },
    [postProfileAction.rejected]: (state) => {
      state.isProfileLoading = false;
      state.isProfileLoaded = true;
      state.profile = {};
    },

    [getCertificationsAction.fulfilled]: (state, action) => {
      state.isCertificationsLoading = false;
      state.isCertificationsLoaded = true;
      state.certifications = action.payload;
    },
    [getCertificationsAction.pending]: (state) => {
      state.isCertificationsLoading = true;
    },
    [getCertificationsAction.rejected]: (state) => {
      state.isCertificationsLoading = false;
      state.isCertificationsLoaded = true;
      state.certifications = [];
    },

    [putCertificationsAction.fulfilled]: (state, action) => {
      state.isCertificationsLoading = false;
      state.isCertificationsLoaded = true;
      state.certifications = action.payload;
    },
    [putCertificationsAction.pending]: (state) => {
      state.isCertificationsLoading = true;
    },
    [putCertificationsAction.rejected]: (state) => {
      state.isCertificationsLoading = false;
      state.isCertificationsLoaded = true;
      state.certifications = [];
    },

    [postCertificationsAction.fulfilled]: (state, action) => {
      state.isCertificationsLoading = false;
      state.isCertificationsLoaded = true;
      state.certifications = action.payload;
    },
    [postCertificationsAction.pending]: (state) => {
      state.isCertificationsLoading = true;
    },
    [postCertificationsAction.rejected]: (state) => {
      state.isCertificationsLoading = false;
      state.isCertificationsLoaded = true;
      state.certifications = [];
    },

    [deleteCertificationsAction.fulfilled]: (state, action) => {
      state.isCertificationsLoading = false;
      state.isCertificationsLoaded = true;
      state.certifications = action.payload;
    },
    [deleteCertificationsAction.pending]: (state) => {
      state.isCertificationsLoading = true;
    },
    [deleteCertificationsAction.rejected]: (state) => {
      state.isCertificationsLoading = false;
      state.isCertificationsLoaded = true;
      state.certifications = [];
    },

    [getEducationAction.fulfilled]: (state, action) => {
      state.isEducationLoading = false;
      state.isEducationLoaded = true;
      state.education = action.payload;
    },
    [getEducationAction.pending]: (state) => {
      state.isEducationLoading = true;
    },
    [getEducationAction.rejected]: (state) => {
      state.isEducationLoading = false;
      state.isEducationLoaded = true;
      state.education = [];
    },

    [postEducationAction.fulfilled]: (state, action) => {
      state.isEducationLoading = false;
      state.isEducationLoaded = true;
      state.education = action.payload;
    },
    [postEducationAction.pending]: (state) => {
      state.isEducationLoading = true;
    },
    [postEducationAction.rejected]: (state) => {
      state.isEducationLoading = false;
      state.isEducationLoaded = true;
      state.education = [];
    },

    [putEducationAction.fulfilled]: (state, action) => {
      state.isEducationLoading = false;
      state.isEducationLoaded = true;
      state.education = action.payload;
    },
    [putEducationAction.pending]: (state) => {
      state.isEducationLoading = true;
    },
    [putEducationAction.rejected]: (state) => {
      state.isEducationLoading = false;
      state.isEducationLoaded = true;
      state.education = [];
    },

    [deleteEducationAction.fulfilled]: (state, action) => {
      state.isEducationLoading = false;
      state.isEducationLoaded = true;
      state.education = action.payload;
    },
    [deleteEducationAction.pending]: (state) => {
      state.isEducationLoading = true;
    },
    [deleteEducationAction.rejected]: (state) => {
      state.isEducationLoading = false;
      state.isEducationLoaded = true;
      state.education = [];
    },

    [getEmrExperienceAction.fulfilled]: (state, action) => {
      state.isEmrExperienceLoading = false;
      state.isEmrExperienceLoaded = true;
      state.emrExperience = action.payload;
    },
    [getEmrExperienceAction.pending]: (state) => {
      state.isEmrExperienceLoading = true;
    },
    [getEmrExperienceAction.rejected]: (state) => {
      state.isEmrExperienceLoading = false;
      state.isEmrExperienceLoaded = true;
      state.emrExperience = [];
    },

    [postEmrExperienceAction.fulfilled]: (state, action) => {
      state.isEmrExperienceLoading = false;
      state.isEmrExperienceLoaded = true;
      state.emrExperience = action.payload;
    },
    [postEmrExperienceAction.pending]: (state) => {
      state.isEmrExperienceLoading = true;
    },
    [postEmrExperienceAction.rejected]: (state) => {
      state.isEmrExperienceLoading = false;
      state.isEmrExperienceLoaded = true;
      state.emrExperience = [];
    },

    [putEmrExperienceAction.fulfilled]: (state, action) => {
      state.isEmrExperienceLoading = false;
      state.isEmrExperienceLoaded = true;
      state.emrExperience = action.payload;
    },
    [putEmrExperienceAction.pending]: (state) => {
      state.isEmrExperienceLoading = true;
    },
    [putEmrExperienceAction.rejected]: (state) => {
      state.isEmrExperienceLoading = false;
      state.isEmrExperienceLoaded = true;
      state.emrExperience = [];
    },

    [getExperienceAction.fulfilled]: (state, action) => {
      state.isExperienceLoading = false;
      state.isExperienceLoaded = true;
      state.experience = action.payload;
    },
    [getExperienceAction.pending]: (state) => {
      state.isExperienceLoading = true;
    },
    [getExperienceAction.rejected]: (state) => {
      state.isExperienceLoading = false;
      state.isExperienceLoaded = true;
      state.experience = [];
    },

    [putExperienceAction.fulfilled]: (state, action) => {
      state.isExperienceLoading = false;
      state.isExperienceLoaded = true;
      state.experience = action.payload;
    },
    [putExperienceAction.pending]: (state) => {
      state.isExperienceLoading = true;
    },
    [putExperienceAction.rejected]: (state) => {
      state.isExperienceLoading = false;
      state.isExperienceLoaded = true;
      state.experience = [];
    },

    [postExperienceAction.fulfilled]: (state, action) => {
      state.isExperienceLoading = false;
      state.isExperienceLoaded = true;
      state.experience = action.payload;
    },
    [postExperienceAction.pending]: (state) => {
      state.isExperienceLoading = true;
    },
    [postExperienceAction.rejected]: (state) => {
      state.isExperienceLoading = false;
      state.isExperienceLoaded = true;
      state.experience = [];
    },

    [deleteExperienceAction.fulfilled]: (state, action) => {
      state.isExperienceLoading = false;
      state.isExperienceLoaded = true;
      state.experience = action.payload;
    },
    [deleteExperienceAction.pending]: (state) => {
      state.isExperienceLoading = true;
    },
    [deleteExperienceAction.rejected]: (state) => {
      state.isExperienceLoading = false;
      state.isExperienceLoaded = true;
      state.experience = [];
    },

    [getLicenceAction.fulfilled]: (state, action) => {
      state.isLicenceLoading = false;
      state.isLicenceLoaded = true;
      state.licence = action.payload;
    },
    [getLicenceAction.pending]: (state) => {
      state.isLicenceLoading = true;
    },
    [getLicenceAction.rejected]: (state) => {
      state.isLicenceLoading = false;
      state.isLicenceLoaded = true;
      state.licence = [];
    },

    [postLicenceAction.fulfilled]: (state, action) => {
      state.isLicenceLoading = false;
      state.isLicenceLoaded = true;
      state.licence = action.payload;
    },
    [postLicenceAction.pending]: (state) => {
      state.isLicenceLoading = true;
    },
    [postLicenceAction.rejected]: (state) => {
      state.isLicenceLoading = false;
      state.isLicenceLoaded = true;
      state.licence = [];
    },

    [putLicenceAction.fulfilled]: (state, action) => {
      state.isLicenceLoading = false;
      state.isLicenceLoaded = true;
      state.licence = action.payload;
    },
    [putLicenceAction.pending]: (state) => {
      state.isLicenceLoading = true;
    },
    [putLicenceAction.rejected]: (state) => {
      state.isLicenceLoading = false;
      state.isLicenceLoaded = true;
      state.licence = [];
    },

    [deleteLicenceAction.fulfilled]: (state, action) => {
      state.isLicenceLoading = false;
      state.isLicenceLoaded = true;
      state.licence = action.payload;
    },
    [deleteLicenceAction.pending]: (state) => {
      state.isLicenceLoading = true;
    },
    [deleteLicenceAction.rejected]: (state) => {
      state.isLicenceLoading = false;
      state.isLicenceLoaded = true;
      state.licence = [];
    },

    [getReferenceAction.fulfilled]: (state, action) => {
      state.isReferenceLoading = false;
      state.isReferenceLoaded = true;
      state.reference = action.payload;
    },
    [getReferenceAction.pending]: (state) => {
      state.isReferenceLoading = true;
    },
    [getReferenceAction.rejected]: (state) => {
      state.isReferenceLoading = false;
      state.isReferenceLoaded = true;
      state.reference = [];
    },

    [postReferenceAction.fulfilled]: (state, action) => {
      state.isReferenceLoading = false;
      state.isReferenceLoaded = true;
      state.reference = action.payload;
    },
    [postReferenceAction.pending]: (state) => {
      state.isReferenceLoading = true;
    },
    [postReferenceAction.rejected]: (state) => {
      state.isReferenceLoading = false;
      state.isReferenceLoaded = true;
      state.reference = [];
    },

    [putReferenceAction.fulfilled]: (state, action) => {
      state.isReferenceLoading = false;
      state.isReferenceLoaded = true;
      state.reference = action.payload;
    },
    [putReferenceAction.pending]: (state) => {
      state.isReferenceLoading = true;
    },
    [putReferenceAction.rejected]: (state) => {
      state.isReferenceLoading = false;
      state.isReferenceLoaded = true;
      state.reference = [];
    },

    [deleteReferenceAction.fulfilled]: (state, action) => {
      state.isReferenceLoading = false;
      state.isReferenceLoaded = true;
      state.reference = action.payload;
    },
    [deleteReferenceAction.pending]: (state) => {
      state.isReferenceLoading = true;
    },
    [deleteReferenceAction.rejected]: (state) => {
      state.isReferenceLoading = false;
      state.isReferenceLoaded = true;
      state.reference = [];
    },

    [getPreferenceAction.fulfilled]: (state, action) => {
      state.isPreferenceLoaded = true;
      state.isPreferenceLoading = false;
      state.preference = action.payload;
    },
    [getPreferenceAction.pending]: (state) => {
      state.isPreferenceLoading = true;
    },
    [getPreferenceAction.rejected]: (state) => {
      state.isPreferenceLoading = false;
      state.isPreferenceLoaded = true;
      state.preference = {};
    },

    [putPreferenceAction.fulfilled]: (state, action) => {
      state.isPreferenceLoaded = true;
      state.isPreferenceLoading = false;
      state.preference = action.payload;
    },
    [putPreferenceAction.pending]: (state) => {
      state.isPreferenceLoading = true;
    },
    [putPreferenceAction.rejected]: (state) => {
      state.isPreferenceLoaded = true;
      state.isPreferenceLoading = false;
      state.preference = {};
    },

    [postPreferenceAction.fulfilled]: (state, action) => {
      state.isPreferenceLoaded = true;
      state.isPreferenceLoading = false;
      state.preference = action.payload;
    },
    [postPreferenceAction.pending]: (state) => {
      state.isPreferenceLoading = true;
    },
    [postPreferenceAction.rejected]: (state) => {
      state.isPreferenceLoaded = true;
      state.isPreferenceLoading = false;
      state.preference = {};
    },
  },
});

export default profileReducer;
