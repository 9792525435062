import { AxiosRequest } from "../axiosRequest";

const URL = "prof-experience";

export function getExperience() {
  return AxiosRequest.get(URL);
}

export function postExperience(data) {
  return AxiosRequest.post(URL, data);
}

export function putExperience(data) {
  return AxiosRequest.put(URL, data);
}

export function deleteExperience(data) {
  return AxiosRequest.delete(URL, { data });
}
