import { AxiosRequest } from "../axiosFacilitiesRequest";

export function getUnits() {
  return AxiosRequest.get("facility-units");
}

export function getAppliedShifts(id) {
  return AxiosRequest.get(`apply?shift_id=${id}`);
}

export function approveShift(id) {
  return AxiosRequest.put(`facility-approve?applied_id=${id}`);
}

export function assignShift(id) {
  return AxiosRequest.put(`facility-approve?applied_id=${id}`);
}

export function createShift(data) {
  return AxiosRequest.post(`shift-defn`, data);
}

export function getShifts({ month, year }) {
  return AxiosRequest.get(`facility-calander?year=${year}&month=${month}`);
}

export function getSwapShifts(type) {
  return AxiosRequest.get(`/facility-swap-requests?swap_status=${type}`);
}

export function getAppliedUsers(shiftDateId, userId) {
  return AxiosRequest.get(`/facility-swap-users?shift_bydate_id=${shiftDateId}&user_id=${userId}`);
}

export function approveAppliedUser(swapId) {
  return AxiosRequest.put(`/facility-swap-users?shift_swap_id=${swapId}`);
}

export function getUserProfile(userId) {
  return AxiosRequest.get(`/user-details?user_id=${userId}`);
}

export function getSwapShiftsStats() {
  return AxiosRequest.get(`/facility-swap-count`);
}
