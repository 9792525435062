import { Suspense } from "react";
import { createTheme, CssBaseline } from "@mui/material";
import { ThemeProvider } from "@mui/styles";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { StyledEngineProvider } from "@mui/material/styles";

import { baseTheme } from "./theme";
import { configureStore } from "./redux/store";
import CatchAllErrors from "./components/common/CatchAllError";
import AppRoot from "./pages/AppRoot";
import "./scroll.css";

import { ResponsiveDebugger } from "./components/utils/ResponsiveDebugger";
import { LoaderProgress } from "./components/common/LoaderProgress";

const theme = createTheme(baseTheme);
const store = configureStore();

function App() {
  return (
    <Provider store={store}>
      <BrowserRouter basename="/">
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            {/* <ResponsiveDebugger /> */}
            <Suspense fallback={<LoaderProgress />}>
              <CssBaseline />
              <CatchAllErrors>
                <AppRoot />
              </CatchAllErrors>
            </Suspense>
          </ThemeProvider>
        </StyledEngineProvider>
      </BrowserRouter>
    </Provider>
  );
}

export default App;
