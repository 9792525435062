export const AUTH_STAGES = {
  signup: "/signup",
  signin: "/signin",
  new: "/new",
  thank_you: "/thank_you",
  congratulation: "/congratulations",
  forgot_password: "/forgot_password",
  professional: "/professional",
  hospital: "/hospital",
  otp: "/otp",
};

export const ACCOUNT_TYPES = {
  professional: "professional",
  hospital: "hospital",
};

export const ACCOUNT_LOOKUP_ID = {
  professional: 47,
  hospital: 48,
};
