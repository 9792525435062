import { combineReducers } from "@reduxjs/toolkit";
import authenticationReducer from "./authenticationReducer";
import commonReducer from "./common";
import profileReducer from "./profileReducer";
import shiftsReducer from "./shiftsReducer";
import facilitiesReducer from "./facilitiesReducer";

const appReducer = combineReducers({
  authenticationReducer: authenticationReducer.reducer,
  commonReducer: commonReducer.reducer,
  profileReducer: profileReducer.reducer,
  shiftsReducer: shiftsReducer.reducer,
  facilitiesReducer: facilitiesReducer.reducer,
});

export default function rootReducer(state, action) {
  if (action.type === "USER_LOGOUT") {
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
}
